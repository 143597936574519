
 .MuiDrawer-paper {
  background: #242A45 !important;
         color: white !important;
     width: 280px !important;
 }
.app {
    overflow: hidden;
}
.main {
    overflow-x: auto;
}

 .MuiTableCell-root {
     width: fit-content !important;
 }

a {
    text-decoration: none !important;
}