.feedback_form {
  background-color: #242A45;
  padding: 30px;
  justify-content: space-between;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  padding-bottom: 10px;
}

.feedback_form label,
.feedback_form span {
  color: #fff !important;
}
