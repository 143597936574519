.signup {
    background: #1A2038;
    height: 100vh;
    align-items: center;
    justify-content: center;
}

.signup-card {
    max-width: 800px;
    border-radius: 12px !important;
}

img {
    width: 300px;
    height: 200px;
}
