.pincode_butns {
    border-radius: 5px;
    background-color: #e7a750;
    color: black;
    font-weight: 600;
    margin: 10px 5px 5px 5px;
    cursor: pointer;
    border: 0;
    padding: 4px 7px;
}
